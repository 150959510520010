<template>
  <section class="about__page" style="height: 100%;">
    <div class="container d-flex justify-start align-center">
      <Breadcrumb class="territorio_section_bc-color">
        <template v-slot:breadcrumbLinks>
          <router-link
            tag="p"
            :to="{ name: link.href }"
            v-for="(link, idx) in breadcrumbItems"
            :key="idx"
          >
            {{ link.text }}
          </router-link>
        </template>
      </Breadcrumb>
    </div>
    <v-row class="territorio_nav d-flex justify-center align-center">
      <v-col class="d-flex justify-center align-center">
        <nav
          class="d-flex justify-space-around align-center pt-3 col-12 col-md-8 mt-4"
        >
          <div class="d-flex justify-center flex-column align-center">
            <router-link :to="{ name: 'sobre_pedras' }">
              Sobre
            </router-link>
          </div>
          <div class="">
            <router-link :to="{ name: 'sobre_rompimento_pedras' }">
              O Rompimento
            </router-link>
          </div>
          <div class="">
            <router-link :to="{ name: 'mapa_territorios' }">
              Mapa
            </router-link>
          </div>
        </nav>
      </v-col>
    </v-row>
    <transition>
      <router-view />
    </transition>
    <ScrollTop />
  </section>
</template>

<script>
import Breadcrumb from "@/layout/Breadcrumb";
import ScrollTop from "@/components/ScrollTop.vue";

export default {
  components: { Breadcrumb, ScrollTop },
  data() {
    return {
      active: "active",
      breadcrumbItems: [
        {
          text: "< Territórios /",
          href: "mapa_territorios",
        },
        {
          text: "Pedras",
          href: "sobre_pedras",
        },
      ],
    };
  },
};
</script>

<style scoped>
.territorio_nav {
  margin-bottom: 2em;
}

a {
  color: #5d3f36;
  cursor: pointer;
  text-decoration: none;
}

.router-link-exact-active {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #e06919;
}

.router-link {
  color: #5d3f36;
}
</style>
